import { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";

import { themeDark, themeLight } from "themes";
import GlobalStyle from "globalStyle";
import logo from "./logo.png";
import instagram from "./instagram.svg";
import facebook from "./facebook.svg";

const Container = styled.main`
    padding-bottom: calc(env(safe-area-inset-bottom) + 0px);
    width: 100vw;
    height: 100vh;
    overflow: clip;
    display: grid;
    grid-template-rows: 15rem 1fr 1fr auto;
    justify-items: center;
    align-items: center;
    gap: 2rem;
`;

const Info = styled.div`
    overflow: clip;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    align-items: center;
    gap: 2rem 6rem;

    > div {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 2rem;
    }
`;

const Label = styled.div`
    > div {
        display: grid;
        align-items: center;
        gap: 1rem;
    }
`;

const Social = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
`;

const Icon = styled.a`
    overflow: clip;

    img {
        height: 4rem;
    }
`;

function App() {
    const [darkTheme, setDarkTheme] = useState(
        localStorage.getItem("darkTheme") || ""
    );

    useEffect(() => {
        localStorage.setItem("darkTheme", darkTheme);
    }, [darkTheme]);

    return (
        <>
            <ThemeProvider theme={darkTheme ? themeDark : themeLight}>
                <GlobalStyle />
            </ThemeProvider>
            <Container>
                <img src={logo} alt="logo" />
                <Info>
                    <Label>
                        <label>ubicacion</label>
                        <div>
                            <p>Encontranos en Jara y Berutti, ambas esquinas</p>
                            <p>Av. Juan Hector Jara 184/185</p>
                        </div>
                    </Label>
                    <Label>
                        <label>horarios</label>
                        <div>
                            <pre>lunes a viernes | 09:00 - 17:30</pre>
                            <pre>sabados | 09:00 - 13:00</pre>
                        </div>
                    </Label>
                    <Label>
                        <label>telefonos</label>
                        <div>
                            <pre>223 475 2410</pre>
                            <pre>223 476 2955</pre>
                        </div>
                    </Label>
                    <Label>
                        <label>correo</label>
                        <div>
                            <p>imprentagiola@hotmail.com</p>
                        </div>
                    </Label>
                </Info>
                <Social>
                    <Icon
                        href="https://www.instagram.com/giographicimprenta/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={instagram} alt="instagram" />
                    </Icon>
                    <Icon
                        href="https://www.facebook.com/IMPRENTAGIOGRAPHIC/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={facebook} alt="facebook" />
                    </Icon>
                </Social>
                {darkTheme ? (
                    <button
                        type="button"
                        onClick={() => {
                            setDarkTheme("");
                        }}
                    >
                        Tema Claro
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={() => {
                            setDarkTheme("true");
                        }}
                    >
                        Tema Oscuro
                    </button>
                )}
            </Container>
        </>
    );
}

export default App;
